.footer-container {
  background-color: #d9d9d9; /* Gray background */
  padding: 20px 80px; /* Add padding for spacing on both sides */
  display: flex;
  justify-content: space-between; /* Align sections horizontally */
  align-items: center;
  font-family: 'Manrope', sans-serif;
}

.footer-item {
  font-size: calc((var(--heading-4-size-value) - 1) * 1.2vw + 1rem); /* Increased font size */
  color: hsla(223.57, 68.85%, 52.16%, 1); /* Blue font color */
  white-space: nowrap; /* Prevent line break */
  font-weight: 400; /* Reduced font weight */
}

.footer-right {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align sections to the top */
  gap: 50px; /* Space between "Keep updated" and "Contact" sections */
}

.section {
  display: flex;
  flex-direction: column; /* Stack the title and content vertically */
  align-items: center; /* Center-align content within the section */
  gap: 12px; /* Add space between title and content */
}

.section-title {
  font-size: 18px; /* Increased font size */
  font-weight: 500; /* Reduced font weight for title */
  color: black;
  text-align: center;
}

.social-icons {
  display: flex;
  gap: 15px; /* Add spacing between icons */
  justify-content: center; /* Center-align icons */
}

.social-icon {
  color: hsla(223.57, 68.85%, 52.16%, 1); /* Blue color for icons */
  font-size: 22px; /* Increased icon size */
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: hsla(223.57, 68.85%, 42.16%, 1); /* Slightly darker blue on hover */
}

.contact-email {
  color: hsla(223.57, 68.85%, 52.16%, 1); /* Blue font color for email */
  text-decoration: none;
  font-size: 16px; /* Increased font size */
  font-weight: 400; /* Reduced font weight */
  text-align: center;
}

.contact-email:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align content */
    gap: 20px; /* Add spacing between sections */
    display: flex !important;
  }

  .footer-right {
    flex-direction: column; /* Stack sections vertically */
    gap: 40px; /* Add spacing between sections */
    align-items: center;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-item {
    order: 2; /* Move copyright section to the bottom */
    text-align: center; /* Center-align text */
    margin-top: 40px;
    display: block !important;
  }

  .contact-email {
    margin-bottom: 20px; /* Add space below the email */
  }
}