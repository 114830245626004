
  
  .grid-container {
    display: flex;
    flex-direction: column;
    width: "100%";
    margin: 0  auto;
    height: auto;
  }
  
  .row {
    display: grid;
    gap: 0;
    justify-content: center;
  }
  
  .two-col {
    grid-template-columns: repeat(2, minmax(400px, 150px));
  }
  
  .three-col {
    grid-template-columns: repeat(3, minmax(400px, 150px));
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid black;*/
    background-color: white;
    aspect-ratio: 3/2;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .blue {
    background-color: white;
    
  }
  
  .circle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: min(100%, 650px);
    aspect-ratio: 1;
    background-color: white;
    border: 2px solid white;
    /*border-radius: 50%;*/
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    text-align: center; /* Ensures text is centered */
}

.avatar-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px; /* Space between rows */
}

.name-row {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px; /* Adjust spacing between rows */
}

.role-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    color: white; /* Adjust text color for visibility */
}

.role-row a {
    margin-left: 5px; /* Space between role and LinkedIn icon */
}

  
  @media (max-width: 600px) {
    .two-col {
      grid-template-columns: repeat(2, minmax(100px, 120px));
    }
  
    .three-col {
      grid-template-columns: repeat(3, minmax(90px, 100px));
    }
  }
  
  @media (max-width: 400px) {
    .grid-container {
      gap: 0;
    }
  
    .row {
      gap: 0;
    }
  
    .two-col {
      grid-template-columns: repeat(2, minmax(80px, 100px));
    }
  
    .three-col {
      grid-template-columns: repeat(3, minmax(70px, 80px));
    }
  
    .circle {
      width: min(50%, 30px);
    }
  }